<template>
	<div data-route>
		<page-header
			v-if="getNameString"
			heading="Edit details"
		/>
		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
				v-if="formLoaded"
			>
				<input-field
					name="First name"
					type="text"
					rules="required"
					v-model="user.firstName"
				/>
				<input-field
					name="Last name"
					type="text"
					rules="required"
					v-model="user.lastName"
				/>
				<select-field
					name="Your experience level"
					rules="required"
					:options="experienceLevelOptions"
					v-model="user.experienceLevel"
				/>
				<select-field
					name="Your preferred bidding system"
					rules="required"
					:options="biddingSystemOptions"
					v-model="user.biddingSystem"
				/>
				<select-field
					name="Your timezone"
					rules="required"
					:options="timezones"
					v-model="user.timezone"
				/>
				<actions
					:actions="getActions"
					:isWaiting="isWaiting"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>

	import PageHeader           from '@/components/ui/PageHeader';
	import InputField           from '@/components/forms/InputField';
	import SelectField          from '@/components/forms/SelectField';
	import Actions              from '@/components/ui/Actions';
	import actionClick          from '@/mixins/actionClick';
	import invalidFormMessage   from '@/mixins/invalidFormMessage';
	import routeParams          from '@/mixins/routeParams';
	import api                  from '@/services/api';
	import { experienceLevels } from '@/consts';
	import { biddingSystems }   from '@/consts';
	import timezones            from '@/timezones.json';

	export default {
		metaInfo: {
			title: 'Edit details'
		},
		components: {
			PageHeader,
			InputField,
			SelectField,
			Actions
		},
		mixins: [actionClick, routeParams, invalidFormMessage],
		data: () => ({
			isWaiting: undefined,
			experienceLevelOptions: experienceLevels,
			biddingSystemOptions: biddingSystems,
			user: {
				firstName:        '',
				lastName:         '',
				experienceLevel:  '',
				biddingSystem:    ''
			},
			formLoaded: false,
			timezones: timezones.flatMap((timezone) => {
				return timezone.mainCities.map((city) => {
					return {
						text: `${city} - UTC ${timezone.rawFormat.split(' - ')[0]}`,
						value: timezone.name
					};
				});
			})
		}),
		computed: {
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							type: 'submit',
							btnId: 'submit'
						},
						{
							text: 'Cancel',
							route: '/'
						}
					]
				};
			},
			getBreadcrumbRoute () {
				if (!this.getUserId) {
					return false;
				}
				return `/admin/users/${this.getUserId}`;
			},
			getUser () {
				if (!this.user ||
						!this.user.id) {
					return false;
				}
				return this.user;
			},
			getNameString () {
				if (!this.getUser) {
					return false;
				}
				return `${this.getUser.firstName} ${this.getUser.lastName}`;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/users',
						text: 'Users'
					},
					{
						path: `/admin/users/${this.getUserId}`,
						text: this.getNameString
					},
					{
						path: `/admin/users/${this.getUserId}/edit`,
						text: 'Edit'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setProfile();
		},
		methods: {
			async setProfile () {
				const user = await api.admin.getUserById({
					userId: this.getUserId
				});
				if (!user) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.user = {
					...this.user,
					...user
				};
				this.formLoaded = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				this.isWaiting = 'submit';
				const user = await api.admin.updateUserById({
					user: this.user,
					userId: this.getUserId
				});
				if (!user) {
					this.isWaiting = undefined;
					return false;
				}
				this.isWaiting = undefined;
				await this.$router.push(this.getBreadcrumbRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'User has been updated'
				});
			}
		}
	};

</script>
